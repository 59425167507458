// import Vue from 'vue';
import {
	BAROMETER_SUBMIT,
	NAVIGATION_OPEN,
	SAVE_TOKEN,
	SEND_TOKEN,
	SAVE_NAVIGATION,
	ADD_ARTICLE,
	STORE_BULLETIN_CATEGORIES,
	STORE_BULLETIN,
	UPDATE_ARTICLE,
	STORE_IMPRESSIONS,
	ADD_MY_EVENT,
	REMOVE_MY_EVENT,
	STORE_EVENTS,
	STORE_NEWS,
	DELETE_ARTICLE,
	STORE_CONTACTS,
	STORE_MEDIATHEK
} from '../actions/general';
import { AUTH_LOGOUT } from '../actions/auth';
import apiCall, { ApiDefaults } from "@/utils/api_call";


const state = {
	navigation: 'is-opening',
	navigationItems: [],
	barometerSubmitted: false,
	tokenSent: false,
	pushToken: null,
	newsItems: [],
	newsCategories: [],
	calendarEvents: [],
	impressionItems: [],
	bulletinItems: [],
	bulletinCategories: [],
	myEvents: [],
	contacts: [],
	mediathek: []
};

const getters = {
	navigationState: ( state ) => state.navigation,
	barometerSubmitted: ( state ) => state.barometerSubmitted,
	isTokenSent: ( state ) => state.tokenSent,
	getToken: ( state ) => state.pushToken,
	contacts: (state) => state.contacts,
	contactsBirthday: (state) => {
		let today = new Date(),
			list = new Object();
		today.resetTo('hour');
		state.contacts.filter(( item ) => {
			if( item.birthday !== 0 ) {
				let date = new Date();
				date.setTime(item.birthday * 1000);
				date.setFullYear(today.getFullYear());
				date.resetTo('hour');
				let daysUntil =  (date.getTime() - today.getTime()) / (1000*3600*24);
				if( today.getWeek() >= (date.getWeek() - 1) && date >= today && daysUntil < 30 ) {
					return true
				}
			}
			return false;
		}).forEach(( item ) => {
			let date = new Date(item.birthday * 1000);
			date.resetTo('hour');
			date.setFullYear(2000);
			let idx = date.getTime() / 1000;
			if ( false === Object.prototype.hasOwnProperty.call(list, idx) ) {
				list[ idx ] = [];
			}
			list[ idx ].push(item);
		}, list);
		return list;

	},
	newsItems: ( state ) => state.newsItems.sort(( a, b ) => {
		return b.datetime - a.datetime;
	}),
	newsItemsCount: ( state ) => state.newsItems.length,
	newsCategories: ( state ) => state.newsCategories,
	getNews: (state) => (uid) => {
		let found = state.newsItems.filter( item => item.uid === parseInt(uid) );
		return found[0];
	},
	calendarEvents: ( state ) => state.calendarEvents,
	calendarEventsCount: ( state ) => state.calendarEvents.length,
	commingEvents: ( state ) => {
		let now = new Date();
		return state.calendarEvents.filter(( item ) => {
			return item.start > (now.getTime() / 1000);
		}).sort(( a, b ) => a.start - b.start);
	},
	eventsForDay: ( state ) => ( tstamp ) => {
		return state.calendarEvents.filter( item => {
			let start = new Date(item.start * 1000);
			// let end = new Date(evt.end * 1000);

			let day = new Date(tstamp * 1000);
			// if ( day.isBetween(start, end, 'day') ) {
			return ( day.isSame(start, 'day') );
		},tstamp).sort(( a, b ) => {
			let aEnd = new Date(a.end * 1000);
			let bEnd = new Date(b.end * 1000);

			if ( aEnd.getDay() === bEnd.getDay() ) {
				if ( aEnd.getHours() === bEnd.getHours() ) return 0;
				return (aEnd.getHours() - bEnd.getHours());
			}
			return (bEnd.getDay() - aEnd.getDay());
		})
	},
	myEvents: ( state ) => {
		return state.calendarEvents.filter(( item ) => {
			return state.myEvents.indexOf(item.uid) !== -1;
		});
	},
	impressionItems: ( state ) => state.impressionItems,
	bulletinItems: ( state ) => state.bulletinItems,
	bulletinItemsCount: ( state ) => state.bulletinItems.length,
	bulletinCategories: ( state ) => state.bulletinCategories,
	getBulletin: (state) => (uid) => {
		let found = state.bulletinItems.filter( item => item.uid === parseInt(uid) );
		return found[0];
	},
	bulletinItemsByCategory: ( state ) => ( cid ) => {
		return state.bulletinItems.filter(item => item.categories.map(( val ) => val.uid).indexOf(cid) !== -1);
	},
	getMediaPost: (state) => (uid) => {
		let found = state.mediathek.filter( item => item.uid === parseInt(uid) );
		return found[0];
	},
	mediaCount: (state) => state.mediathek.length,
	getNewsComment: (state)=>(uid,target='news')=>{
		if( target === 'news' ) {
			let found = state.newsItems.find(item => item.uid === uid);
			if ( found ) {
				return found.comments;
			}
		}
		else if( target=== 'post') {
			let found = state.bulletinItems.find( item => item.uid === uid );
			if( found ) {
				return found.comments;
			}
		}
		return [];
	},
	mediaItems : (state) => state.mediathek,
	mediaItemsByCategory: ( state ) => ( cid ) => {
		return state.mediathek.filter(item => item.categories.map(( val ) => val.uid).indexOf(cid) !== -1);
	},
};

const actions = {
	[ AUTH_LOGOUT ]: ( {commit} ) => {
		commit(NAVIGATION_OPEN, 'is-closing');
	},
	[ DELETE_ARTICLE ]: ( {commit}, data ) => {
		let apiSettings = Object.assign({}, ApiDefaults, {
			method: 'get',
			params: {
				id: 8,
				'tx_c3json_jsondataplugin[action]': 'delete',
				'tx_c3json_jsondataplugin[news][__identity]': data.uid
			}
		});

		apiCall(apiSettings).then(( response ) => {
			if ( response.status === 200 ) {
				commit(DELETE_ARTICLE, data);
			}
		});
	}
};


const mutations = {
	[ NAVIGATION_OPEN ]: ( state, navStatus ) => {
		state.navigation = navStatus;
	},
	[ BAROMETER_SUBMIT ]: ( state, submit ) => {
		state.barometerSubmitted = submit;
	},
	[ SEND_TOKEN ]: ( state, sent ) => {
		state.tokenSent = sent;
	},
	[ SAVE_TOKEN ]: ( state, token ) => {
		state.pushToken = token;
	},
	[ SAVE_NAVIGATION ]: ( state, navigation ) => {
		state.navigationItems = navigation;
	},
	[ ADD_ARTICLE ]: ( state, data ) => {
		switch ( data.target ) {
			case 'news' :
				state.newsItems.unshift(data.newArticle);
				break;
			case 'impression' :
				state.impressionItems.unshift(data.newArticle);
				break;
			case 'bulletin' :
				state.bulletinItems.unshift(data.newArticle);
				break;
			case 'events' :
				state.calendarEvents.unshift(data.newArticle);
				break;
		}
	},
	[ STORE_EVENTS ]: ( state, data ) => {
		state.calendarEvents = data;
	},
	[ STORE_NEWS ]: ( state, data ) => {
		state.newsItems = data;
	},
	[ STORE_IMPRESSIONS ]: ( state, data ) => {
		state.impressionItems = data;
	},
	[ STORE_BULLETIN ]: ( state, data ) => {
		state.bulletinItems = data;
	},
	[ STORE_BULLETIN_CATEGORIES ]: ( state, data ) => {
		state.bulletinCategories = data;
	},
	[ UPDATE_ARTICLE ]: ( state, data ) => {
		let list = [], idx;
		switch ( data.target ) {
			case 'news' :
				list = state.newsItems;
				break;
			case 'impression' :
				list = state.impressionItems;
				break;
			case 'bulletin' :
				list = state.bulletinItems;
				break;
			case 'events' :
				list = state.calendarEvents;
				break;
		}

		idx = Object.entries(list).findIndex(( item ) => {
			return item[ 1 ].uid === data.article.uid;
		}, data);

		if ( idx !== -1 ) {
			list[ idx ] = Object.assign(list[ idx ], data.article);
		}
	},
	[ ADD_MY_EVENT ]: ( state, data ) => {
		state.myEvents.push(data);
	},
	[ REMOVE_MY_EVENT ]: ( state, uid ) => {
		let idx = state.myEvents.indexOf(uid);
		if ( idx > -1 ) {
			delete state.myEvents[ idx ];
			state.myEvents = state.myEvents.filter(( item ) => (item != null || typeof item != undefined));
		}
	},
	[ DELETE_ARTICLE ]: ( state, data ) => {
		let idx;
		switch ( data.target ) {
			case 'news' :
				idx = state.newsItems.findIndex(( item ) => {
					return item.uid === data.uid;
				}, data);
				if ( idx > -1 ) {
					delete state.newsItems[ idx ];
					state.newsItems = state.newsItems.filter(( item ) => (item != null || typeof item != undefined));
				}
				break;
			case 'impression' :
				idx = state.impressionItems.findIndex(( item ) => {
					return item.uid === data.uid;
				}, data);
				if ( idx > -1 ) {
					delete state.impressionItems[ idx ];
					state.impressionItems = state.impressionItems.filter(( item ) => (item != null || typeof item != undefined));
				}
				break;
			case 'bulletin' :
				idx = state.bulletinItems.findIndex(( item ) => {
					return item.uid === data.uid;
				}, data);
				if ( idx > -1 ) {
					delete state.bulletinItems[ idx ];
					state.bulletinItems = state.bulletinItems.filter(( item ) => (item != null || typeof item != undefined));
				}
				break;
			case 'events' :
				idx = state.calendarEvents.findIndex(( item ) => {
					return item.uid === data.uid;
				}, data);
				if ( idx > -1 ) {
					delete state.calendarEvents[ idx ];
					state.calendarEvents = state.calendarEvents.filter(( item ) => (item != null || typeof item != undefined));
				}
				break;
		}
	},
	[ STORE_CONTACTS ] : (state, data ) => {
		state.contacts = data;
	},
	[ STORE_MEDIATHEK ] : (state, data ) => {
		state.mediathek = data;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};