<template>
    <div class="app-container" :class="appClass">

        <Header v-if="isAuthenticated || this.$router.name === 'Notfall'" :show-header="true"></Header>
        <div class="app-wrap" :class="{'app-wrap--nosidebar': !showSidebar}">
            <main class="content-wrap">

                <router-view :title="windowTitle">
                </router-view>

            </main>
            <aside id="sidebar" v-if="showSidebar">
                <router-view name="sidebar"></router-view>
            </aside>
        </div>
        <FileDownload />
    </div>
</template>

<script>

import { USER_REQUEST } from './utils/store/actions/user';
import Header from './components/layouts/Header';
import { mapGetters } from 'vuex';
import FileDownload from './components/Plugins/FileDownload';

export default {
	name: 'App',
	components: {
		FileDownload,
		Header
	},
    data() {
		return {
        }
    },
	computed: {
		windowTitle: function () {

			return 'Wir sind NEW';
		},
		appClass: function () {
			let appClass = [ 'app--' + this.$store.getters.getViewMode ];
			if ( this.$route.name )
				appClass.push('app--' + this.$route.name.toLowerCase());

			return appClass;
		},
		showSidebar: function () {
			return this.isAuthenticated;
				// if( this.$route.path.indexOf('infocenter') !== -1 ){
				// 	return true;
                // }
				// else if( [ 'SingleNews', 'Beitrag' ].indexOf(this.$route.name) === -1 ) {
				// 	return true;
            //     // }
            // }
		},

		...mapGetters([ 'isAuthenticated', 'authStatus', 'getViewMode', 'getProfile', 'getProfileImage', 'favorites/getFavorites' ])
	},

	created() {
		if ( this.$store.getters.isAuthenticated ) {
			this.$store.dispatch(USER_REQUEST);
		}
	},
    updated(){
		if ( false === this.$store.getters.isAuthenticated && this.$route.name !== 'PasswordChange' ) {
			this.$router.push({name: 'Login'});
		}
    },
	mounted() {
		document.title = 'Wir sind NEW';
	}
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import './assets/css/main.css';
</style>
