import { AUTH_LOGOUT, AUTH_ERROR, AUTH_REQUEST, AUTH_SUCCESS, PASSWORD_RECOVER } from "../actions/auth";
import { USER_REQUEST, USER_SUCCESS } from "../actions/user";
import apiCall, { ApiDefaults } from "@/utils/api_call";
import MD5 from 'crypto-js/md5';

const state = {token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false};

const getters = {
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status
};


const actions = {
	[ AUTH_REQUEST ]: ( {commit, dispatch}, user ) => {
		return new Promise(( resolve, reject ) => {
			commit(AUTH_REQUEST);

			let postData = new URLSearchParams();
			postData.append('permanent', 1);
			postData.append('user', user.username);
			postData.append('pass', user.password);
			let apiParams = Object.assign({}, ApiDefaults,
				{
					method: 'post',
					params: {
						id: 1,
						logintype: 'login'
					},
					data: postData
				});
			apiCall(apiParams)
				.then(( response ) => {
					if ( Object.hasOwnProperty.call(response.data, 'registerStatus') && response.data.registerStatus ) {
						resolve({newuser:true, login:false});
						let BreakChainError = new Error();
						BreakChainError.name = 'breakChain';
						throw BreakChainError;
					}
					return response;
				})
				.then(( response ) => {
					if ( Object.hasOwnProperty.call(response.data, 'loginStatus') && response.data.loginStatus ) {
						let token = '';
						/** ToDo: [] generierten token durch cookie ersetzen */
						if ( Object.hasOwnProperty.call(response.headers, 'Set-Cookie') ) {
							token = response.headers[ 'Set-Cookie' ];
						}
						else {
							token = MD5(JSON.stringify(response.data.loggedInUser)).toString();
						}

						let commitData = response.data.loggedInUser;
						commitData.token = token;
						localStorage.setItem('user-token', token);
						localStorage.setItem('tmp-profile', JSON.stringify(commitData));
						commit(AUTH_SUCCESS, commitData);
						commit(USER_SUCCESS, response.data.loggedInUser);
						dispatch(USER_REQUEST);
						resolve(response.data);
					}
					else {
						commit(AUTH_ERROR);
						let err = new Error('Anmelde Daten waren nicht korrekt.');
						reject(err);
					}
				})
				.catch(( err ) => {
					if ( err.name !== 'breakChain' ) {
						commit(AUTH_ERROR);
						reject();
					}
					else{
						resolve();
					}
				});

		});
	},
	[ AUTH_LOGOUT ]: ( {commit} ) => {
		return new Promise(( resolve ) => {
			commit(AUTH_LOGOUT);
			let token = localStorage.getItem('user-token');

			let apiParams = Object.assign(ApiDefaults,
				{method: 'post', params: {id: 2, logintype: 'logout', token: token}});
			apiCall(apiParams)
				.then(( response ) => {
					if ( response.status === 200 ) {
						localStorage.removeItem('user-token');
						localStorage.removeItem('tmp-profile');
						resolve();
					}
				})
				.catch(( err ) => {
					console.log(err);
				});
		});
	},
	[ PASSWORD_RECOVER ]: ( {commit}, email ) => {
		return new Promise(( resolve, reject ) => {
			commit(AUTH_REQUEST);

			let postData = new URLSearchParams();

			postData.append('tx_c3json_jsondataplugin[userEmail]', email);
			postData.append('tx_c3json_jsondataplugin[action]', 'password');

			let apiParams = Object.assign({}, ApiDefaults, {
				method: 'post',
				params: {
					id: 1
				},
				data: postData
			});


			apiCall(apiParams)
				.then(() => {
					resolve();
				})
				.catch(() => {
					reject();
				});

		});
	}
};

const mutations = {
	[ AUTH_REQUEST ]: ( state ) => {
		state.status = 'loading';
	},
	[ AUTH_SUCCESS ]: ( state, resp ) => {
		state.status = 'success';
		state.token = resp.token;
		state.hasLoadedOnce = true;
	},
	[ AUTH_ERROR ]: ( state ) => {
		state.status = 'error';
		state.hasLoadedOnce = true;
	},
	[ AUTH_LOGOUT ]: ( state ) => {
		state.token = '';
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
