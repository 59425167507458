<template>
    <header id="mainHeader" class="header" v-show="showHeader" key="head">
        <div class="header-wrap">
            <router-link :to="{path:'/'}" class="header-logo" name="Hauptseite"><inline-svg :src="require('@/assets/logo.svg')" width="100" /></router-link>
            <kapp-nav></kapp-nav>
        </div>
    </header>
</template>

<script>
import KappMainNav from '../KappMainNav';

export default {
	name: "Header",
	props: {
		subTitle: {
			type: String,
			default: ' '
		},
		showHeader: {
			type: Boolean,
			default: true
		}
	},
	components: {
		'kapp-nav': KappMainNav,

	},

	data() {
		return {
			categories: [],
			profileVisible: false,
			showSearch: false,
			offline: false,
			desktopVariant: false
		};
	},
	computed: {
		title: function () {
			let title = this.page.pageTitle;
			if ( this.page.subTitle != '' )
				title = title + '<br/><small>' + this.page.subTitle + '</small>';

			return title;
		},
		page: function () {
			// defaults
			let pageSettings = {
				pageTitle: this.$router.currentRoute.value.meta.title,
				pageIcon: '',// this.$route.meta.image,
				subTitle: this.subTitle,
				backLink: {name: 'MainView'},
				showSearch: ([ 'Kontakte', 'Kalender' ].indexOf(this.$router.name) > -1)
			};

			return pageSettings;
		},
	},
	created() {
	},
	mounted() {
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
	},
	beforeUnmount() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},

};
</script>

<style scoped></style>
