// import './plugins/axios'
import { createApp } from 'vue';
import App from './App.vue';
import store from './utils/store';
import router from './utils/router';
import InlineSvg from 'vue-inline-svg';
import { createI18n } from 'vue-i18n';
import deMessages from '@/lang/de';
import VueSSE from 'vue-sse';
import VueMatomo from 'vue-matomo';
import Vue3TouchEvents from 'vue3-touch-events';
import './registerServiceWorker'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const i18n = createI18n({
	locale: 'de',
	messages: {
		de: deMessages
	}
});

/** PushNotification */
let pushSettings;
if( process.env.NODE_ENV === 'production' ){
	pushSettings = {
		vapidPublicKey: 'BN6AZu7u9uaivni1-6db4-zKRrsY9aZ5_2ukKr3-sPtheZL0sCtezjGzGNGwyb7VqNbY3faNuXqkHLHdH6TamlY=',
		websitePushId: 'web.de.neustadt.app',
		appKey: 'JXJfhQMWTgKd2RA4n9gEiQ',
		token: 'MTpKWEpmaFFNV1RnS2QyUkE0bjlnRWlROnJKdXg4X3JJQkZRM3JIcDdTNzRWRXFPLWVNYjhPS3QycjRfMU9COF9QY0E'
	};
}
else if( process.env.NODE_ENV === 'test' ) {
	pushSettings = {
		vapidPublicKey: 'BL7D2ZEpewSwU7aatbaNeWSw-yrzMquBT1nULXDbW-N1dIpy4qVpZJXiJGUIwSoHH6qxjjxUOiNo_mTJkPbaTuo=',
		appKey: 'PrtKIqNESbKMgbuJJ2DdSQ',
		token: 'MTpQcnRLSXFORVNiS01nYnVKSjJEZFNROjBxdXlFN280RzBCWldka1RCV0xkYk1jV2V2bXp3YmRfMlIwc1JGb1ZkVFE'
	};
}
else{
	pushSettings = {
		vapidPublicKey: 'BMYUMLV6JbBjoLybyKemYzOMNRlZLX-S9SucKKk5rjzVmuIS1ytGuE7SzhyicHmG84GZy7h0Hh3herPMcdu_-3Y=',
		appKey: 'PrtKIqNESbKMgbuJJ2DdSQ',
		token: 'MTpmcTlROUt1OVRpeUZmR2VVb2JGWEZROjU0UkpTczdvanFNX3VFX21rOGtBdnZ2UFo2NG9ET2dLMEJ5UnlXWDJ2VXc'
	};
}
!function ( n, r, e, t, c ) {
	var i,
		o = "Promise" in n,
		u = {
			then: function () {
				return u;
			},
			catch: function ( n ) {
				return n(new Error("Airship SDK Error: Unsupported browser")), u;
			}
		},
		s = o ? new Promise((function ( n, r ) {
			i = function ( e, t ) {
				e ? r(e) : n(t);
			};
		})) : u;
	s._async_setup = function ( n ) {
		if ( o ) try {
			i(null, n(c));
		} catch ( n ) {
			i(n);
		}
	},
		n[ t ] = s;
	var a = r.createElement("script");
	a.src = e,
		a.async = !0,
		a.id = "_uasdk",
		a.rel = t,
		r.head.appendChild(a);
}(window, document, 'https://aswpsdkeu.com/notify/v1/ua-sdk.min.js',
	'UA', pushSettings);


const app = createApp(App);
app.config.globalProperties.$filters = {

	formatDate: function ( tstamp, format ) {
		if ( typeof tstamp === 'undefined' ) return null;

		let $date;
		if( typeof tstamp === 'object' && typeof tstamp.getMonth === 'function' ){
			$date = tstamp
		}
		else {
			$date = new Date();
			if ( parseInt(tstamp) ) {
				$date.setTime(tstamp * 1000);
			}
			else {
				let bday = tstamp.split('-');
				$date.setFullYear(bday[ 0 ]);
				$date.setMonth(parseInt(bday[ 1 ]) - 1, parseInt(bday[ 2 ]));
			}
		}
		return $date.format(format);
	},
	timeSince: function ( tstamp ) {
		let d = new Date();
		d.setTime(tstamp * 1000);
		return d.since();
	},
	stripTags: function ( string ) {
		let div = document.createElement('DIV');
		div.innerHTML = string;
		string = div.innerText;
		div.remove();
		return string;
	}
};


if( process.env.NODE_ENV === 'production' ){

	app.use(VueMatomo, {
		host: "//new-perspektiven.de/wp-content/plugins/matomo/app/",
		siteId: 1,
		trackerFileName: 'matomo',
		router: router,
		enableLinkTracking: true,
		requireConsent: false,
		trackInitialView: true,
		disableCookies: false,
		enableHeartBeatTimer: false,
		heartBeatTimerInterval: 15,
		debug: false,
		userId: undefined,
		cookieDomain: undefined,
		domains: undefined,
		preInitActions: []
	})
}

let $sseUrl = 'https://neustadtapp.myc3.dev/backend/?type=1000';
if( process.env.NODE_ENV === 'production' ) {
	$sseUrl = 'https://wirsindnew.neustadt.de/backend/?type=1000';
}

app.use(store)
   .use(router)
   .use(VueToast, {position: 'top'})
   .use(Vue3TouchEvents)
   .use(VueSSE, {
		format: 'json',
		url: $sseUrl
   })
   .use(InlineSvg)
   .use(i18n)
   .component('inline-svg', InlineSvg);

app.mount('#app');
