import VuexPersistance from 'vuex-persist';
import { createStore } from 'vuex';
import general from './modules/general';
import user from './modules/user';
import auth from './modules/auth';
import favorites from './modules/favorites';

const vuexLocal = new VuexPersistance({
	storage: window.localStorage
});

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
	modules: {
		general,
		auth,
		user,
		favorites
	},
	strict: debug,
	plugins: [ vuexLocal.plugin ]
});