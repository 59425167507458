import { FAV_ADD, FAV_REMOVE, FAV_ADD_GROUP, FAV_TOGGLE, FAV_UPDATE } from '../actions/favorites';
import api, { ApiDefaults } from '../../api_call';

const state = { status: '', groups : [], favorites : [[],[],[]] };

const getters = {
	groupExists : (state) => (grp) => (state.groups.indexOf(grp) > -1),
	isFavorite : (state) => function (data) {
		let gid = state.groups.indexOf( data.group );
		if( gid !== -1 ) {
			return state.favorites[gid].find( (item) => item.uid === data.uid,data);
		}
		return false;
	},
	getFavorites : (state) => state.favorites
};

const actions = {

	[FAV_TOGGLE] : ({commit}, favData ) => {
		let apiParams = Object.assign({},ApiDefaults,{
			method: 'post',
			params: {
				id: 7,
				'tx_c3json_jsondataplugin[action]': 'friend',
				'tx_c3json_jsondataplugin[controller]': 'JsonDataUser',
				'tx_c3json_jsondataplugin[user]': favData.user,
				'tx_c3json_jsondataplugin[friend]': favData.contact.uid
			}
		});
		api(apiParams).then((response)=>{
			if( response.status === 200 ) {
				if( response.data.action === 'add' )
					commit(FAV_ADD, favData);
				else
					commit(FAV_REMOVE, favData);
			}
		});

	},
	// [FAV_REMOVE] : ({commit}, favData ) => {
	// 	let apiParams = Object.assign({},ApiDefaults,{
	// 		method: 'post',
	// 		params: {
	// 			id: 7,
	// 			'tx_c3json_jsondataplugin[action]': 'friend',
	// 			'tx_c3json_jsondataplugin[controller]': 'JsonDataUser',
	// 			'tx_c3json_jsondataplugin[user]': favData.user,
	// 			'tx_c3json_jsondataplugin[friend]': favData.contact.uid
	// 		}
	// 	});
	// 	api(apiParams).then((response)=>{
	// 		if( response.status === 200 ) {
	// 			commit( FAV_REMOVE, favData );
	// 		}
	// 	});
	//
	// }


};

const mutations = {
	[FAV_UPDATE] : (state,data) => {
		let gid = state.groups.indexOf( data.target );

		if( gid === -1 ) {
			gid = state.groups.push( data.target );
			gid--;
			state.favorites[ gid ] = [];
		}
		state.favorites[ gid ] = data.list;
	},
	[FAV_ADD_GROUP] : (state, group ) => {
		state.groups.push( group.name );
	},
	[FAV_ADD] : (state, favData ) => {
		let gid = state.groups.indexOf( favData.group );

		if( gid === -1 ) {
			gid = state.groups.push( favData.group );
			gid--;
			state.favorites[ gid ] = [];
		}
		state.favorites[ gid ].push( favData.contact );
		state.favorites[ gid ] = Object.values(state.favorites[ gid ]).filter((item)=> {
			return Object.prototype.hasOwnProperty.call(item, 'uid');
		});
	},
	[FAV_REMOVE] : (state, favData) => {
		let gid = state.groups.indexOf( favData.group ),
			uid = state.favorites[ gid ].findIndex( (item) => item.uid === favData.contact.uid , favData )

		if( uid > -1 ) {
			delete state.favorites[ gid ][ uid ];
			state.favorites[ gid ] = state.favorites[ gid ].filter( (item) => ( item != null || typeof item != undefined ) );
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};