export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const BAROMETER_SUBMIT = 'Barometer submitted';
export const SEND_TOKEN = 'Send token to Server';
export const SAVE_TOKEN = 'Save token';
export const SAVE_NAVIGATION = 'store navigation';
export const STORE_NAVIGATION = 'store navigation';
export const STORE_NEWS = 'Store News Items';
export const STORE_NEWS_CATEGORIES = 'Store News Categories';
export const STORE_EVENTS = 'Store Calender events';
export const NEWS_SET_LIKES = 'News - set Likes';
export const STORE_IMPRESSIONS = 'Store Impressions';
export const IMPRESSION_SET_LIKES = 'Impressions - set Likes';
export const ADD_ARTICLE = 'Add Post';
export const UPDATE_ARTICLE = 'Update Article';
export const STORE_BULLETIN = 'Store - Bulletin Items';
export const STORE_BULLETIN_CATEGORIES = 'Store - Bulletin Categories';
export const ADD_MY_EVENT = 'Kalendar - add to my Events';
export const REMOVE_MY_EVENT = 'Kalendar - remove from my Events';
export const REMOVE_BULLETIN = 'Remove Bulettin item from Store';
export const DELETE_ARTICLE = 'Remove News/Bulettin/Event item from Server';
export const STORE_CONTACTS = 'Contacts - Store';
export const STORE_MEDIATHEK = 'Mediathek - Store';