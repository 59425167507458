<template>
    <nav class="app-nav">
        <ul class="menu"
            :data-itemcount="navigation.length">
            <li class="menu-item" :class="{'is-current' : isCurrent(data.endpoint) }"
                v-for="(data,idx) in this.navigation" v-bind:key="data.name">
                <router-link :to="data.endpoint" :key="idx" class="menu-link">
                    <inline-svg :src="data.icon" width="20" />
                    <span>{{ data.title }}</span>
                    <span class="menu-notification" v-if="menuBubble[data.name]">{{ menuBubble[ data.name ] }}</span>
                </router-link>
            </li>
            <li class="menu-item is-parent" :class="{'is-current':showSubmenu,'router-active-parent': inSubmenu}">
                <span class="menu-link" @click="toggleSubmenu">
                    <inline-svg :src="require('@/assets/images/ic_more.svg')" width="20" />
                    <span>Mehr</span>
                </span>

                <ul class="menu lvl-2" v-show="showSubmenu">
                    <li class="menu-item" :class="{'is-current' : isCurrent(data.endpoint) }" v-for="(data,idx) in this.submenu" v-bind:key="data.name">
                        <router-link :to="data.endpoint" :key="idx" class="menu-link">
                            <inline-svg :src="data.icon" width="20" />
                            <span>{{ data.title }}</span>
                            <span class="menu-notification" v-if="menuBubble[data.name]">{{ menuBubble[ data.name ] }}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import EventsBus from '../utils/EventsBus';

let sseClient;

export default {
	name: "KappMainNav",
	components: {},
	data() {
		return {
			showSubmenu: false,
			submenu: [
				{
					uid: 13,
					title: 'Kalender',
					name: 'events',
					icon: require('@/assets/images/ic_kalender.svg'),
					endpoint: {name: 'Kalender'}
				},
				{
					uid: 22,
					title: 'Mediathek',
					name: 'mediathek',
					icon: require('@/assets/images/ic_mediathek.svg'),
					endpoint: {name: 'Mediathek'}
				},
				{
					uid: 10,
					title: 'Infocenter',
					name: 'Infocenter',
					// icon: 'ic_infocenter',
					icon: require('@/assets/images/ic_infocenter.svg'),
					endpoint: {path: '/infocenter/alles'}
				},
				{
					uid: 15,
					title: 'Mein Profil',
					name: 'Profil',
					icon: require('@/assets/images/ic_profil.svg'),
					endpoint: {path: '/mein_profil', name:'Profil'}
				}

			],
			menu: [

				{
					uid: 2,
					title: 'NEWs',
					name: 'Main',
					icon: 'ic_news',
					endpoint: {name: 'Aktuelles'}
				},
				{
					uid: 15,
					title: 'Aktuelles',
					name: 'Aktuelles',
					icon: 'ic_aktuelles',
					endpoint: {name: 'Aktuelles'}
				},
				{
					uid: 13,
					title: 'Kalender',
					name: 'Kalender',
					endpoint: {name: 'Kalender'}
				},
				{
					uid: 14,
					title: 'Profile',
					name: 'Kontakte',
					endpoint: {name: 'Kontakte'}
				},

				{
					uid: 10,
					title: 'Infocenter',
					name: 'Infocenter',
					icon: 'ic_infocenter',
					endpoint: {name: 'Infocenter', params: {slug: 'alles'}}
				}

			],
			isOpen: false,
			isOpening: false,
			isClosing: false,
			menuBubble: {
				news: false,
				bulletin: false,
				events: false,
                mediathek: false
			}
		};
	},
	props: {
		status: {
			type: String,
			required: false
		}
	},
	computed: {
		inSubmenu: function(){
            return ['Kalender','Profil'].indexOf(this.$route.name) !== -1 || this.$route.path.toLowerCase().indexOf('infocenter') !== -1;
        },
		allowedNavigation: function () {
			let list = [];
			this.menu.forEach(function ( item ) {
				list.push(item.uid);
			});
			return list;
		},
		navigation: function () {
			let list = [
				{
					uid: 2,
					title: 'NEWs',
					name: 'news',
					icon: require('@/assets/images/ic_feed.svg'),
					endpoint: {name: 'Main'}
				},
				{
					uid: 13,
					title: 'Beiträge',
					name: 'bulletin',
					icon: require('@/assets/images/ic_dashboard.svg'),
					endpoint: {name: 'Posts'}
				},
				{
					uid: 22,
					title: 'Mediathek',
					name: 'mediathek',
					icon: require('@/assets/images/ic_mediathek.svg'),
					endpoint: {name: 'Mediathek'}
				},

				{
					uid: 14,
					title: 'Profile',
					name: 'Kontakte',
					icon: require('@/assets/images/ic_kontakt.svg'),
					endpoint: {name: 'Profile'}
				}
			];
			list = list.filter(( item ) => {
				return this.allowedNavigation.includes(item.uid) || item.required;
			});
			return list;
		},

		...mapGetters([ 'newsItemsCount', 'calendarEventsCount', 'bulletinItemsCount', 'mediaCount', 'isAuthenticated' ])
	},
	methods: {
		isCurrent: function ( key ) {

			return key.name === this.$route.name;
		},
		toggleSubmenu: function () {
			this.showSubmenu = !this.showSubmenu;
		},
		handleSSEMessage: function ( data ) {
			let newsDiff = (data.news - this.newsItemsCount),
                bulletinDiff = (data.bulletin - this.bulletinItemsCount),
				eventsDiff = (data.events - this.calendarEventsCount),
                mediaDiff = (data.mediathek - this.mediaCount);

			if ( newsDiff > 0 ) {
				this.menuBubble.news = newsDiff;
				EventsBus.trigger('newPost:news', newsDiff);
			}
			else {
				this.menuBubble.news = false;
			}

			if ( bulletinDiff > 0 ) {
				this.menuBubble.bulletin = bulletinDiff;
				EventsBus.trigger('newPost:bulletin', bulletinDiff);
			}
			else {
				this.menuBubble.bulletin = false;
			}

			if ( eventsDiff > 0 ) {
				this.menuBubble.events = eventsDiff;
				EventsBus.trigger('newPost:events', eventsDiff);
			}
			else {
				this.menuBubble.events = false;
			}

			if ( mediaDiff > 0 ) {
				this.menuBubble.mediathek = mediaDiff;
				EventsBus.trigger('newPost:media', mediaDiff);
			}
			else {
				this.menuBubble.mediathek = false;
			}
		}
	},
	sse: {
		cleanup: true
	},
	mounted() {
		if( this.isAuthenticated ) {
			let dev = process.env.NODE_ENV == 'development';
			sseClient = this.$sse.create({
				format: 'json',
				url: (dev ? 'https://neustadtapp.myc3.dev/backend/' : window.location.origin + '/backend/') + '?type=1000'
			});
			sseClient.disconnect();
			sseClient.on('error', ( e ) => {
				console.log(e);
			});
			sseClient.on('message', this.handleSSEMessage);

			sseClient.connect()
                .then(() => {
					// Unsubscribes from event-less messages after 7 seconds
					setTimeout(() => {
						sseClient.off('message', this.handleMessage);
						// console.log('Stopped listening to event-less messages!');
					}, 12000);

					// Unsubscribes from chat messages after 14 seconds
					// setTimeout(() => {
					//     sse.off('chat', this.handleChat);
					//     console.log('Stopped listening to chat messages!');
					// }, 14000);
                })
                .catch(( err ) => {
					// When this error is caught, it means the initial connection to the
					// events server failed.  No automatic attempts to reconnect will be made.
					console.error('Failed to connect to server', err);
                });
		}
		else {
			sseClient.off();
        }
	},
	beforeUnmount() {
		// Make sure to close the connection with the events server
		// when the component is destroyed, or we'll have ghost connections!
		sseClient.disconnect();

		// Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
		// and the SSEManager would have automatically disconnected during beforeDestroy.
	},
	watch: {
		'$route': function () {
			this.showSubmenu = false;
		}
	}

};
</script>

<style scoped>
</style>
