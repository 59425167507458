export default {
	// Global
	maxLength: 'max. {m} Zeichen',
	optional: '(optional)',
	favorites: 'Favoriten',
	login_title: 'Anmelden',
	login_button: 'anmelden',
	login_username: 'E-Mail',
	login_password: 'Passwort',
	password_recovery: 'Passwort vergessen',
	password_button: 'Passwort zurücksetzen',
	password_recovery_title: 'Passwort vergessen',
	back: 'zurück',
	login_error_wrong_userdata: 'Benutzername oder Passwort stimmen nicht überein!',
	passwords_not_identic: 'Die angegebenen Passwörter stimmen nicht über ein!',
	like: 'interessiert mich',
	unlike: 'kein Interesse mehr',
	no_likes: '',
	more_likes: '{c} weitere',

	load_more: 'weitere anzeigen',
	and: 'und',
	birthday_info: 'feiert am <strong>{date}</strong> Geburtstag',
	birthday_title: 'Bevorstehende Geburtstage',

	user_created_title: 'neuer Benutzer wurde angelegt',
	user_created: 'Ein neuer Benutzer wurde mit Ihren Daten angelegt. Wir haben Ihnen eine Email zur Bestätigung geschickt.',
	close: 'Schließen',

	profile_is_not_valid: 'Damit Sie Kommentare schreiben können, müssen Sie zuerst Ihr Profil ausfüllen.',
	// Main
	spotlight_title: "Im Spotlight",
	newsfeed_title: "Newsfeed", //"Newsfeed <small>({count})</small>",
	profiles_title: "Neuigkeiten auf Profilen",
	new_messages: "keine Nachrichten | {c} neue Nachricht | {c} neue Nachrichten",

	// News
	news: 'NEWs',
	news_title_create: 'neuer Eintrag',
	news_description_create: '',
	news_title: 'Titel',
	news_teaser: 'Teaser',
	news_teaser_placeholder: 'Teaser eingeben',

	// Posts
	gallery: 'Galerie',
	post_headline_0 : 'NEWs',
	post_headline_3 : 'Kalender',
	post_headline_4 : 'Beiträge',
	post_title_create_0 : 'neue NEWs',
	post_title_create_3 : 'neue Veranstaltung',
	post_title_create_4 : 'neuer Beitrag',
	post_title_update_0 : 'NEWs bearbeiten',
	post_title_update_3 : 'Veranstaltung bearbeiten',
	post_title_update_4 : 'Beitrag bearbeiten',
	post_description_create_0: 'Erstellen Sie ganz einfach eine NEWs.',
	post_description_create_3: '',
	post_description_create_4: 'Erstellen Sie ganz einfach einen Beitrag, der von anderen Nutzer:innen kommentiert werden kann.',
	post_description_update_0: '',
	post_description_update_3: '',
	post_description_update_4: '',
	post_submit_create: 'Veröffentlichen',
	post_submit_update: 'Speichern',
	price: 'Preis',

	// Kommentare
	comment: "keine Kommentare | {c} Kommentar | {c} Kommentare",
	comment_add: 'Kommentar hinzufügen',
	comment_new: 'neuer Kommentar',
	comment_placeholder: 'Kommentar schreiben',
	comment_submit: 'Kommentar senden',

	// Kalendar
	no_events: 'Es wurden keine Veranstaltungen für diesen Tag gefunden',
	form_title: 'Titel',
	form_title_placeholder: 'Titel eingeben',
	form_description: 'Beschreibung',
	form_description_placeholder: 'Beschreibung eingeben',
	form_start: 'Beginn',
	form_start_placeholder: 'Beginn eingeben',
	form_end: 'Ende',
	form_end_placeholder: 'Ende eingeben',
	form_location: 'Veranstaltungsort',
	form_location_placeholder: 'Veranstaltungsort eingeben',
	form_organizer: 'Veranstalter',
	form_organizer_placeholder: 'Veranstalter eingeben',
	form_mainimage: 'Titelbild',
	form_mainimage_placeholder: 'Titelbild hochladen',
	form_gallery: 'weitere Bilder',
	form_gallery_placeholder: 'weitere Bilder hochladen',
	form_image_update_info: 'Bilder werden beim Hochladen ersetzt.',
	form_price: 'Preis',
	form_price_placeholder: 'Preis eingeben',
	form_category: 'Kategorie',
	form_choose_category: 'Kategorie auswählen ...',
	form_fullday: 'Ganztags',
	form_bodytext: 'Beschreibung',
	form_bodytext_placeholder: 'Beschreibung eingeben',
	location: 'Veranstaltungsort',
	organizer: 'Veranstalter',
	likes: 'Interesse',
	create_new_event: 'Erstelle neuen Kalender Eintrag',


	// Profil
	profile_birthday: 'Geburtstag',
	profile_devise: 'Zitat / Motto',
	profile_division: 'Sachgebiet',
	profile_email: 'E-Mail-Adresse',
	profile_email_privat: 'E-Mail-Adresse',
	profile_expertise: 'Expertisen',
	profile_expertise_placeholder: 'z.B. Zertifikate, Ersthelfer, …',
	profile_fax: 'Faxnummer',
	profile_firstname: 'Vorname',
	profile_first_name: 'Vorname',
	profile_hobby: 'Persönliche Interessen',
	profile_image: 'Profilbild',
	profile_lastname: 'Name',
	profile_last_name: 'Name',
	profile_mobil: 'Handy',
	profile_telephone2: 'Telefonnummer',
	profile_telephone: 'Telefonnummer',
	profile_telephone_mobile2: 'Handy',
	profile_telephone_mobile: 'Diensthandy',
	profile_telephone_placeholder: '09602 79-123456',
	profile_work_position: 'Position',
	profile_work_scope: 'Aufgabenbereich',
	profile_www: 'Web',
	profile_city: 'Ort',
	profile_street: 'Straße',
	profile_zip: 'PLZ',

	profile_info: 'hat das Profil aktualisiert:',
	profile_info_m: 'hat Sein Profil aktualisiert:',
	profile_info_f: 'hat Ihr Profil aktualisiert:',

	push_news_spotlight: 'NEWs - Spotlight',
	push_news_pressemitteilung: 'NEWs - Pressemitteilungen',
	push_news_hausintern: 'NEWs - Hausintern',
	push_veranstaltungen: 'Kommende Veranstaltungen',
	push_post_suche: 'Beiträge - Suche',
	push_post_biete: 'Beiträge - Biete',
	push_post_events: 'Beiträge - Events',
	push_post_allgemein: 'Beiträge - Allgemein',

	title: 'Titel',


	// Errors
	error_passwort_short: 'Das gewählte Passwort muss mindestens 6 Zeichen lang sein',
	error_passwort_not_same: 'Die gewählten Passwörter stimmen nicht überein',

	error_profile_firstName: 'Vorname ist ein Pflichtfeld',
	error_profile_lastName: 'Name ist ein Pflichtfeld',
	error_profile_division: 'Sachgebiet ist ein Pflichtfeld',
	error_profile_workPosition: 'Position ist ein Pflichtfeld',
	error_profile_workScope: 'Aufgabenbereich ist ein Pflichtfeld',
	error_profile_telephone: 'Telefonnummer ist ein Pflichtfeld',
	error_profile_email: 'E-Mail-Adresse ist ein Pflichtfeld',
};