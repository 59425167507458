<template>
    <div class="modal" v-if="showModal">
        <div class="modal-toolbar">
            <span class="modal-controls">
                <span @click="firstPage" title="erste Seite" class="icon-to-start"><inline-svg :src="require('@/assets/images/ic_first_page.svg')" /></span>
                <span @click="prevPage" title="vorherige" class="icon-left-dir "><inline-svg :src="require('@/assets/images/ic_back.svg')" /></span>
                <span>{{ page }}/{{ pageCount }}</span>
                <span @click="nextPage" title="nächste" class="icon-right-dir"><inline-svg :src="require('@/assets/images/ic_next.svg')" /></span>
                <span @click="lastPage" title="letzte Seite" class="icon-to-end"><inline-svg :src="require('@/assets/images/ic_last_page.svg')" /></span>
            </span>
            <a :href="downloadFile" target="_blank" class="modal-button modal-button--download icon-zurueck"><inline-svg :src="require('@/assets/images/ic_download.svg')" /><span class="sr-only">Download</span></a>
            <span class="modal-button modal-button--close icon-plus_x" @click="close"><inline-svg :src="require('@/assets/images/ic_close.svg')" /></span>
        </div>
        <pdf class="pdfview" ref="pdf" :page="page" :src="file" @numPages="setPageCount" @error="error"></pdf>
    </div>
</template>

<script>
// import api, {ApiDefaults} from "../../utils/api_call";
import pdf from 'vue3-pdf';
import EventsBus from '../../utils/EventsBus';

var loadingTask = pdf.createLoadingTask;

export default {
	name: "FileDownload",
	components: {
		pdf
	},
	data() {
		return {
			routeFile: this.$route.params.file,
			file: false,
			pdfData: '',
			page: 1,
			pageCount: 1
		};
	},
	computed: {
		iframeSrc: function () {
			return this.file;
		},
		showModal: function () {
			return (this.file !== false);
		},
		downloadFile: function ( ) {
			return this.file;
			// return this.file.replace('https://','http://');
		},
	},
	props: {
		asOverlay: {
			type: Boolean,
			default: false
		}

	},
	methods: {
		error: function(err){
			console.log( err );
        },
		setPageCount: function ( cnt ) {
			console.log('pages: ' + cnt);
			this.pageCount = cnt;
		},
        loaded: function(){
			console.log( 'loaded' );
        },
		firstPage: function () {
			this.page = 1;
		},
		lastPage: function () {
			this.page = this.pageCount;
		},
		nextPage: function () {
			this.page = Math.min(this.page + 1, this.pageCount);
		},
		prevPage: function () {
			this.page = Math.max(this.page - 1, 1);
		},
		close: function () {
			this.page = 1;
			this.pageCount = 1;
			this.file = false;
			EventsBus.trigger('modal:close');
		}
	},
	watch: {
		'file': function ( uri ) {
			if ( uri ) {
				this.pdfData = loadingTask(uri);
				this.pdfData.promise.then(pfd => {
					this.pageCount = pfd.numPages;
                })
			}
		},

	},

	created() {
		EventsBus.on('modal:open', ( url ) => this.file = url);
	},
	mounted() {

	}
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500 !important;
    background: rgba(50, 50, 50, .8);
    overflow-x: hidden !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
}

.modal-enter-active {
    animation: modal .3s ease-out forwards;
}

.modal-leave-active {
    animation: modal .2s ease-out reverse forwards;
}

.modal-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 56px;
    padding: 14px 30px;
    background: #000;
    text-align: right;
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.modal-button {
    font-size: 28px;
    vertical-align: middle;
    color: #FFF;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
}

.modal-button svg{
    fill: #FFF;
}

.modal-controls {
    align-self: flex-start;
    margin-right: auto;
}

.modal-controls span {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    line-height: 24px;
    vertical-align: middle;
    cursor: pointer;
}

.modal-controls span svg {
    fill: #FFF;
}

.modal-controls span::before {
    margin: 0;
    font-size: 1.8em;
    line-height: 24px;
    vertical-align: middle;
}

iframe {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    max-width: none;
    height: 100%;
}

.modal iframe,
.modal object,
.modal .pdfview {
    margin: 56px 0 0;
    width: 100%;
    height: calc(100% - 56px);
    display: block;
}

@keyframes modal {
    from {
        opacity: 0;
        transform: translateY(25%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }

}
</style>
