import axios from 'axios';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { setupCache } from 'axios-cache-adapter';
import { AUTH_LOGOUT } from '@/utils/store/actions/auth';
import router from '../utils/router';
import vuex from '../utils/store/';

/**
 Axios Cache
 */
const store = localforage.createInstance({
	// List of drivers used
	driver: [
		localforage.INDEXEDDB,
		localforage.LOCALSTORAGE,
		memoryDriver
	],
	// Prefix all storage keys to prevent conflicts
	name: 'new'
});

const cache = setupCache({
	maxAge: 15 * 60 * 1000,
	store
});

const api = axios.create({
	// mode: 'no-cors'
	adapter: cache.adapter
});

api.interceptors.response.use(function ( response ) {
	if ( process.env.NODE_ENV !== 'development' ) {
		if ( response.data.access === false && response.config.params.logintype !== 'logout' ) {
			vuex.dispatch(AUTH_LOGOUT).then(() => {
				router.push({name: 'Login'});
			});
			return false;
		}
		else {
			return response;
		}
	}
	else {
		return response;
	}
}, {vuex, router});

let dev = process.env.NODE_ENV == 'development';

export const ApiDefaults = {
	url: dev ? 'https://neustadtapp.myc3.dev/backend/' : window.location.origin + '/backend/',
	timeout: 20000,
	method: 'GET',
	withCredentials: !dev,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};


export default api;
//
// export const apiCall = ({url, settings, params}) => new Promise( (resolve, reject) => {
//
// 	if( typeof settings === 'undefined' ) settings = {};
//
// 	Object.assign( settings, ApiDefaults );
// 	console.log( 'apiCall', settings, url, params );
// 	let isLoading = true;
//
// 	api.get(url,settings)
// 		.then((response) => {
// 			resolve( response.data );
// 		})
// 		.catch((err)=>{
// 			reject( err );
// 		})
// 		.finally(()=>{
// 			isLoading = false;
// 		});
// });
